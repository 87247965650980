import { ReactComponent as ArrowLeftIcon } from './arrow-left-icon.svg';
import { ReactComponent as DoneIcon } from './done-icon.svg';
import { ReactComponent as ExclamationIcon } from './exclamation-icon.svg';
import { ReactComponent as OrangeExclamationIcon } from './orange-exclamation-icon.svg';
import { ReactComponent as ExclamationFilledIcon } from './exclamation-filled-icon.svg'
import { ReactComponent as WalletImageIcon } from './wallet-image-icon.svg'
import { ReactComponent as LogoutIcon } from './logout-icon.svg'

const Icons = {
  ArrowLeftIcon,
  ExclamationFilledIcon,
  OrangeExclamationIcon,
  ExclamationIcon,
  DoneIcon,
  WalletImageIcon,
  LogoutIcon
}

export default Icons
